import(/* webpackMode: "eager" */ "/opt/build/repo/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/lib/registry.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/LanguageToggle.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/menu-stuff/Menu.js");
;
import(/* webpackMode: "eager", webpackExports: ["BookingsBoxProvider"] */ "/opt/build/repo/context/bookings-box-context.js");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalContextProvider"] */ "/opt/build/repo/context/context.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/build/repo/node_modules/react-hot-toast/dist/index.mjs");
